import { React, Component } from 'react';
import { connect } from 'react-redux'
import ReactSpeedometer from "react-d3-speedometer"
import SplineArea from '../../../../components/charts/apex/SplineArea';
import { bindActionCreators } from "redux";
import PieChart from '../../../../components/charts/apex/PieChart';
import { ALL_CONTENTS_FILTERS, ANALYTICS_DATE_FILTER, GAMES_OPTIONS } from '../../../../helpers/variables'
import _ from 'lodash';
import { ChartBaseComponent } from '../../../../components/charts/chartBaseComponent';
import HorizontalBarchart from '../../../../components/charts/apex/horizonatalBarchart';
import Wrapper from '../../../../Hoc/wrapper';
import { Card, CardBody } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import ReactSelect from 'react-select';
import { DateRangeFilterPopup } from 'components/tubClass/dateRangeFilterPopup';
import * as analyticsAction from 'actions/analytics';
import Apaexlinecolumn from 'components/charts/apex/apaexlinecolumn';

const OverallPerformanceStages = [
    { name: "Low", bgColor: "#00adef66" },
    { name: "Medium", bgColor: "#00adef99" },
    { name: "Average", bgColor: "#00adefcc" },
    { name: "High", bgColor: "#00ADEF" },
]
class StudentsClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 1,
            contentOptions: ALL_CONTENTS_FILTERS,
            gamesOptions: GAMES_OPTIONS,
            filterOptions: ANALYTICS_DATE_FILTER,
            selectedPlayerDetail: null,
            isScroll: false,
            showFilterDatePopup: false,
            selectedDateRange: null,
            noOfDays: "30",
            selectedFilter: ANALYTICS_DATE_FILTER[0],
        };
    }
    async componentDidMount() {
        this.setStickyTabs()
        this.getAllGraphs('30')
    }
    getAllGraphs = (noOfDays) => {
        let params = this.props.match.params
        let studentId = params?.id
        this.props.analyticsAction.fetchStudentGraphData(0, 'studentCurriculumProgress', noOfDays, null, null, studentId)
        this.props.analyticsAction.fetchStudentGraphData(1, 'studentPerformance', noOfDays, null, null, studentId)
        this.props.analyticsAction.fetchStudentGraphData(2, 'studentLearningProgressForHomeSchoolOverall', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(3, 'studentParentalContentCreated', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(4, 'studentParentalGamePlayed', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(5, 'topWordsLearnedByStudent', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(6, 'topTopicsLearnedByStudent', noOfDays, null, 1, studentId)
        this.props.analyticsAction.fetchStudentGraphData(7, 'voacabluaryReportByStudent', noOfDays, null, 1, studentId)
    }

    redirect = (tab) => {
        this.setState({
            tab: tab
        })
    }
    unselectSelectedValue = (type) => {
        if (type == "full") {
            this.setState({
                noOfDays: ''
            }, this.getAllGraphs(''))
        }
    }

    onChangeDateFilter = (name, value) => {
        if (value?.value == 'customDates') {
            this.setState({
                selectedDateRange: null,
                showFilterDatePopup: true,
                selectedFilter: value,
            })
        } else {
            this.setState({
                noOfDays: value?.value,
                selectedDateRange: null,
                selectedFilter: value,
            }, this.getAllGraphs(value?.value))
        }

    }
    setStickyTabs = () => {
        let isScroll = false
        window.addEventListener('scroll', () => {
            if (window.scrollY > 10) {
                isScroll = true;
            } else isScroll = false
            this.setState({
                isScroll: isScroll
            });
        });
    }

    triggerPopup = (val, data) => {
        if (val === "showFilterDatePopup") {
            this.unselectSelectedValue('filterByDate')
        }
        this.setState({
            [val]: !this.state[val]
        })
    }

    onChangeFilterDate = (ranges) => {
        let startDate = moment(ranges.startDate)
        let endDate = moment(ranges.endDate)
        let diff = moment.duration(endDate.diff(startDate)).asDays()
        console.log('diff', diff)
        this.setState({
            noOfDays: diff,
            selectedDateRange: ranges,
            showFilterDatePopup: false
        }, this.getAllGraphs(diff))
    }
    goBack = () => {
        this.props.history.push('/dashboard/analytics/studentreport')
    }
    render() {
        let state = this.state
        let { analytics } = this.props
        let category = localStorage.getItem("schoolCategory");
        const isTubClassLite = category === 'TUBclass Lite';
        return (
            <div className="row ">
                <div className="col-12 pt-2">
                    <div className={`analytics_ovl_cl_main justify-space-between ${state.isScroll ? 'sticky_bx_shadow' : ''}`}>
                        <div className="col-9 d-flex align-items-center">
                            <img
                                src={require('assets/images/left-arrow.svg').default}
                                className="back-btn"
                                onClick={() => this.goBack()}
                                alt="alphatub"
                            />
                            {/* <div className='ml-2'>
                                <h5>{getstudentDetail?.firstName} 's Report</h5>
                            </div> */}
                        </div>
                        <div className="col-md-2 col-5 px-3">
                            <div className="custom-select-filter">
                                <ReactSelect
                                    classNamePrefix={`m-0`}
                                    name={'filterByDate'}
                                    options={state.filterOptions}
                                    placeholder={'Select Filter'}
                                    value={state.selectedFilter}
                                    onChange={(val) => this.onChangeDateFilter('filterByDate', val)}
                                    isClearable={false}
                                />
                            </div>
                            {state.selectedDateRange != null &&
                                <p className='selected_date_range_filter'>({state.selectedDateRange?.startDate} - {state.selectedDateRange.endDate})</p>
                            }
                        </div>
                    </div>
                    <div>
                        <div className='row no-gutters mt-3 report'>
                            <div className='col-12'>
                                <div className='row no-gutters justify-space-around bg_white br-5'>
                                    <div className='col-6'>
                                        <div className='report_type'>
                                            <p>Curriculum Completion</p>
                                        </div>
                                        <div className='mt-55'>
                                            <PieChart
                                                x_axis={analytics.studentsGrpahsList[0].graphData.x_axis}
                                                y_axis={analytics.studentsGrpahsList[0].graphData.y_axis}
                                                colors={['#00ADEF', '#ED7D2B']}
                                                showLegend={true}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className='report_type'>
                                            <p>Overall Performance</p>
                                            <h6 className='mt-2'>Overall score in all content</h6>
                                        </div>
                                        <div className="mt-3 main_speedometer">
                                            <ReactSpeedometer
                                                value={analytics.studentsGrpahsList[1].graphData?.percentage}
                                                minValue={0}
                                                maxValue={100}
                                                segments={4}
                                                // width={170}
                                                fluidWidth={true}
                                                maxSegmentLabels={0}
                                                // needleHeightRatio={0.75}
                                                needleColor={"#F05A28"}
                                                segmentColors={["#00adef66", "#00adef99", "#00adefcc", "#00ADEF"]}
                                                valueTextFontSize={'12px'}
                                                // valueTextFontWeight={'300'}
                                                textColor={"rgb(240, 90, 40)"}
                                            />
                                        </div>
                                        <div className="ovl_performance_stages pb-3">
                                            {OverallPerformanceStages.map(st => (
                                                <div style={{ backgroundColor: st.bgColor }} >
                                                    {st.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Parental Engagement */}
                        {!isTubClassLite &&<div className='row no-gutters mt-4 br-5'>
                            <div className='col-12 report'>
                                <Card>
                                    <CardBody>
                                        {analytics.studentsGrpahsList[3].loading ?
                                            <Skeleton
                                                style={{ marginTop: 15 }}
                                                height="350px"
                                                width="100%"
                                            />
                                            :
                                            <div className='row no-gutters justify-space-between'>
                                                <div className='col-5'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Parental Engagement'}</p>
                                                            </div>
                                                            <div className='main_graph mt-2'>
                                                                <p className='horizontal_chrt_title'>Content Created by Parents by This Week</p>
                                                                <HorizontalBarchart
                                                                    series = {[
                                                                        {
                                                                            name: 'Content Created',
                                                                            data: analytics.studentsGrpahsList[3].graphData?.x_axis,
                                                                        }
                                                                    ]}
                                                                    y_axis={analytics.studentsGrpahsList[3].graphData?.y_axis}
                                                                    colors={['#00e396', '#00ADEF', '#ff4560', '#775dd0']}
                                                                    name={'Content Created'}
                                                                    y_axis_title=""
                                                                    x_axis_title="No Of Contents Created"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p><sup className='report_super_txt'>{''}</sup>{''}</p>
                                                            </div>
                                                            <div className='main_graph'>
                                                                <p className='horizontal_chrt_title'>Games played by Parents by This Week</p>
                                                                <HorizontalBarchart
                                                                    series = {[
                                                                        {
                                                                            name: 'Games Played',
                                                                            data: analytics.studentsGrpahsList[4].graphData?.x_axis,
                                                                        }
                                                                    ]}
                                                                    y_axis={analytics.studentsGrpahsList[4].graphData?.y_axis}
                                                                    colors={['#00ADEF', '#00e396', '#ff4560', '#775dd0']}
                                                                    showToolBar={false}
                                                                    x_axis_title="No Of Games Played"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>}
                        {/* Words & topic list */}
                        <div className='row no-gutters mt-4 br-5'>
                            <div className='col-12 report'>
                                <Card>
                                    <CardBody>
                                        {analytics.studentsGrpahsList[5].loading ?
                                            <Skeleton
                                                style={{ marginTop: 15 }}
                                                height="350px"
                                                width="100%"
                                            />
                                            :
                                            <div className='row no-gutters justify-space-between'>
                                                <div className='col-5'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Word List Report'}</p>
                                                            </div>
                                                            <div className='main_graph mt-2'>
                                                                <p className='horizontal_chrt_title'>Top 5 Words</p>
                                                                <Apaexlinecolumn
                                                                    horizontal = {true}
                                                                    name = {'Exposures'}
                                                                    y_axis = {analytics.studentsGrpahsList[5].graphData?.x_axis}

                                                                    name_2 = {'Correct Answers'}
                                                                    y_axis_2 = {analytics.studentsGrpahsList[5].graphData?.x_axis_1}

                                                                    x_axis_title="No Of Words"
                                                                    offsetY = {0}
                                                                    x_axis = {analytics.studentsGrpahsList[5].graphData?.y_axis}

                                                                    colors={['#00ADEF', '#ED7D2B']}

                                                                    dataEnabledOffsetY = {0}
                                                                    dataEnabledOffsetX = {15}

                                                                    showToolBar={false}
                                                                    y_axis_title=""

                                                                    horizontalAlign = {'center'}
                                                                    legendPosition = {'bottom'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Topic List Report'}</p>
                                                            </div>
                                                            <div className='main_graph'>
                                                                <p className='horizontal_chrt_title'>Top 5 Topics</p>
                                                                <Apaexlinecolumn
                                                                    horizontal = {true}
                                                                    name = {'Exposures'}
                                                                    y_axis = {analytics.studentsGrpahsList[6].graphData?.x_axis}

                                                                    name_2 = {'Correct Answers'}
                                                                    y_axis_2 = {analytics.studentsGrpahsList[6].graphData?.x_axis_1}

                                                                    x_axis_title="No Of Topics"
                                                                    offsetY = {0}
                                                                    x_axis = {analytics.studentsGrpahsList[6].graphData?.y_axis}

                                                                    colors={['#00ADEF', '#ED7D2B']}

                                                                    dataEnabledOffsetY = {0}
                                                                    dataEnabledOffsetX = {15}

                                                                    showToolBar={false}
                                                                    y_axis_title=""

                                                                    horizontalAlign = {'center'}
                                                                    legendPosition = {'bottom'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {/* Words & topic list */}
                        <div className='row no-gutters mt-4 br-5'>
                            <div className='col-12 report'>
                                <Card>
                                    <CardBody>
                                        {analytics.studentsGrpahsList[7].loading ?
                                            <Skeleton
                                                style={{ marginTop: 15 }}
                                                height="350px"
                                                width="100%"
                                            />
                                            :
                                            <div className='row no-gutters justify-space-between'>
                                                <div className='col-6'>
                                                    <div className='row no-gutters'>
                                                        <div className='col-12'>
                                                            <div className='report_heading'>
                                                                <p>{'Vocabulary Report'}</p>
                                                            </div>
                                                            <div className='main_graph mt-2'>
                                                                <p className='horizontal_chrt_title'></p>
                                                                <HorizontalBarchart
                                                                    series = {[
                                                                        {
                                                                            name: 'Games Played',
                                                                            data: analytics.studentsGrpahsList[7].graphData?.x_axis,
                                                                        }
                                                                    ]}
                                                                    y_axis={analytics.studentsGrpahsList[7].graphData?.y_axis}
                                                                    colors={['#00ADEF', '#00e396', '#ff4560', '#775dd0']}
                                                                    showToolBar={false}
                                                                    x_axis_title="No Of Words"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </CardBody>
                                </Card>
                            </div>
                        </div>

                        {/* Learning Progress */}
                        <div className='row no-gutters justify-content-center mt-4 br-5'>
                            <div className='col-12'>
                                <ChartBaseComponent
                                    loading={analytics.studentsGrpahsList[2].loading}
                                    title={'Learning Progress'}
                                    report_number={''}
                                    children={
                                        <SplineArea
                                            x_axis={analytics.studentsGrpahsList[2].graphData?.x_axis}
                                            y_axis={analytics.studentsGrpahsList[2].graphData?.y_axis}
                                            y_axis_title={'Average Score'}
                                            x_axis_title="Date"
                                            showToolBar={true}
                                            colors={['#F14624']}
                                            offsetY={parseInt(state.noOfDays) >= 30 ? 135 : 90}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    {/* Filter date popup*/}
                    <DateRangeFilterPopup
                        showFilterDatePopup={state.showFilterDatePopup}
                        triggerPopup={this.triggerPopup}
                        onChangeFilterDate={this.onChangeFilterDate}
                    />
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ myProfile, analytics, loader, tubGallery, dataToPersist }) => {
    return {
        myProfile,
        loader,
        tubGallery,
        dataToPersist,
        analytics
    };
};

let mapDispatchToProps = (dispatch) => {
    return {
        analyticsAction: bindActionCreators(analyticsAction, dispatch),
        dispatch
    }
}

const StudentReport = Wrapper(connect(mapStateToProps, mapDispatchToProps)(StudentsClass))

export { StudentReport }
